export default {
  // 登录被挤
  getisLogin: {
    method: "post",
    url: "/mine/login/hs",
  },
  // 账户信息
  getMyInfo: {
    method: "post",
    url: "/user/infoRead",
  },
  // 我的客服
  getCustomer: {
    method: "post",
    url: "/user/commissioner",
  },
  // 订单列表
  getOrder: {
    method: "post",
    url: "/trade/order",
  },
  // 订单列表-时间
  getOrderTime: {
    method: "post",
    url: "/order/search/time",
  },
  // 订单详情
  getOrderDel: {
    method: "post",
    url: "/trade/orderInfo",
  },

  // 订单商品
  getOrderGoods: {
    method: "post",
    url: "/trade/orderGoods",
  },
  // 物流详情
  getLogistics: {
    method: "post",
    url: "/trade/orderLogistics",
  },
  // 订单-待付款 - 取消订单
  cancelOrder: {
    method: "post",
    url: "/trade/orderCancle",
  },
  // 订单-待收货 - 确认收货
  deliveryOrder: {
    method: "post",
    url: "/trade/orderReceipt",
  },
  // 订单-再次购买
  buyTwice: {
    method: "post",
    url: "/trade/orderRebuy",
  },
  // 退货单列表
  getReturn: {
    method: "post",
    url: "/trade/returnOrder",
  },
  // 退货单详情
  getReturnDel: {
    method: "post",
    url: "/trade/returnOrderinfo",
  },
  // 申请退货
  applyReturn: {
    method: "post",
    url: "/trade/returnApply",
  },
  // 获取退货原因
  getReturnReason: {
    method: "post",
    url: "/trade/returnReason",
  },
  // 取消退货申请
  getReturnCancle: {
    method: "post",
    url: "/trade/returnCancle",
  },
  // 退货运单号
  getReturnLogistics: {
    method: "post",
    url: "/trade/returnLogistics",
  },
  // 退货单状态
  getReturnStatus: {
    method: "post",
    url: "/condition/returnStatus",
  },
  // 资质证件
  getCertificate: {
    method: "post",
    url: "/user/certificateRead",
  },
  // 资质证件修改
  updateCertificate: {
    method: "post",
    url: "/user/certificateSave",
  },
  // 收藏列表
  getCollect: {
    method: "post",
    url: "/user/collect",
  },
  // 收藏 - 删除
  delCollect: {
    method: "post",
    url: "/user/collectDel",
  },
  // 我的投诉
  getOpinion: {
    method: "post",
    url: "/user/complaints",
  },
  // 投诉类型
  getOpinionType: {
    method: "post",
    url: "/condition/complaintsType",
  },
  // 上传投诉
  updateOpinion: {
    method: "post",
    url: "/user/complaintsSave",
  },
  // 快捷采购列表
  getAssist: {
    method: "post",
    url: "/goods/assist",
  },
  // 采购需求列表
  getDemands: {
    method: "post",
    url: "/user/demands",
  },
  // 采购需求添加
  addDemands: {
    method: "post",
    url: "/user/demandsSave",
  },
  // 采购需求详情
  getDemandsDel: {
    method: "post",
    url: "/user/demandsRead",
  },
  // 我的优惠券
  getCoupon: {
    method: "post",
    url: "/user/coupon",
  },
  // 电子发票
  getEinvoice: {
    method: "post",
    url: "/user/invoice",
  },
  // 电子发票-详情
  einvoiceDel: {
    method: "post",
    url: "/invoice/invoice/read",
  },
  // 积分流水
  getPoints: {
    method: "post",
    url: "/user/scoreList",
  },
  // 积分类型 + 统计
  getPointsType: {
    method: "post",
    url: "/filter/scoreType",
  },
  // 积分兑换记录
  getPointsRecord: {
    method: "post",
    url: "/user/scoreOrder",
  },
  // 积分兑换记录-发放状态
  getPointsffzt: {
    method: "post",
    url: "/prize/ffzt/list",
  },
  // 绑定账号列表
  getAccountList: {
    method: "post",
    url: "/user/accountList",
  },
  // 绑定账号
  bindAccount: {
    method: "post",
    url: "/user/accountBind",
  },
  // 切换用户
  switchAccount: {
    method: "post",
    url: "/user/accountChange",
  },
  // 删除关联账户
  delAccount: {
    method: "post",
    url: "/user/accountUnbind",
  },
  // 省市区
  getCity: {
    method: "post",
    url: "/condition/cityList",
  },
  // 收货地址
  getAddress: {
    method: "post",
    url: "/user/address",
  },
  // 新增/修改收货地址
  saveAddress: {
    method: "post",
    url: "/user/addressSave",
  },
  // 删除地址
  delAddress: {
    method: "post",
    url: "/user/addressDel",
  },
  // 退货地址
  returnAddress: {
    method: "post",
    url: "/user/address",
  },
  // 会员基本信息获取
  getInfo: {
    method: "post",
    url: "/user/infoRead",
  },
  // 商品经营权限
  getManage: {
    method: "post",
    url: "/goods/jyqx/list",
  },
  // 会员基本信息-修改
  updateInfo: {
    method: "post",
    url: "/user/infoSave",
  },
  // 余额提现列表
  getBalance: {
    method: "post",
    url: "/return/balance/index",
  },
  // 发起余额提现
  fetchBalance: {
    method: "post",
    url: "/user/returnBalanceSave",
  },

  // 余额管理属性
  getBillAttribute: {
    method: "post",
    url: "/filter/billType",
  },
  // 余额管理列表
  getBill: {
    method: "post",
    url: "/user/bill",
  },

  // 是否签到
  signread: {
    method: "post",
    url: "/mine/sign/read",
  },
  // 点击签到
  signsave: {
    method: "post",
    url: "/mine/sign/save",
  },

  // 获取抽奖背景图
  luckdrawluckdrawread: {
    method: "post",
    url: "/luckdraw/luckdraw/read",
  },
  //点击抽奖
  luckdrawdrawsave: {
    method: "post",
    url: "/luckdraw/draw/save",
  },

  //获取奖品图片和剩余的抽奖次数
  luckdrawtimesread: {
    method: "post",
    url: "/luckdraw/times/read",
  },

  //获取弹窗数据
  luckdrawprizeindex: {
    method: "post",
    url: "/luckdraw/prize/index",
  },
  //充值
  recharge: {
    method: "post",
    url: "/order/recharge",
  },
  // 订单数量
  orderCounts: {
    method: "post",
    url: "/trade/orderCounts",
  },
  // 下载订单
  orderDown: {
    method: "post",
    url: "/trade/orderDown",
  },
  // 用户协议
  //type 101:用户协议
  // type 102:隐私政策
  agreement: {
    method: "post",
    url: "/user/agreement",
  },
};
