<template>
  <div id="app" class="page-content">
    <div class="ad-content" v-if="adFlag && isHome && showADV">
      <div class="ad-img">
        <div class="close-ad" @click="closeAD">
          <i class="el-icon-close" size="20"></i>
        </div>
        <img :src="homeADimg" @click.stop="adJump" alt="" />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
// import { LgetItem, LreItem } from "./utils/storage";
export default {
  name: "App",
  data() {
    return {
      fullscreenLoading: false,
      adFlag: true,
      showADV: false,
      homeADimg: "",
      adData: "",
      isHome: false,
    };
  },
  watch: {
    $route(to) {
      if (to.path == "/index/home") {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    },
  },
  created() {
    this.$store.dispatch("getBadge");
    this.$api("home.getHomeAdv").then((res) => {
      if (res.code == 200) {
        if (res.data.is_show == 101) {
          this.showADV = true;
        }
        this.homeADimg = res.data.logo;
        this.adData = res.data;
      }
    });
    // }
  },
  updated() {
    // if (LgetItem("token")) {
    //   this.$api("account.getisLogin").catch(() => {
    //     LreItem("token");
    //     LreItem("info");
    //     this.$bus.$emit("logout"); //登录被挤
    //     this.$store.dispatch("getBadge");
    //   });
    // }
  },
  methods: {
    closeAD() {
      this.adFlag = false;
    },
    adJump() {
      console.log("广告跳转---", this.adData.pcJump);
    },
  },
};
</script>

<style lang="less">
@import "assets/css/base.less";
@import "assets/css/global.less";
.page-content {
  position: relative;
  .ad-content {
    background-color: rgba(31, 29, 29, 0.8);
    z-index: 9998;
    width: 100vw;
    height: 100vw;
    position: fixed;
    // padding-top: 200px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .ad-img {
      width: 600px;
      height: 400px;
      margin: 0 auto;
      // margin-top: 50%;
      // margin-left: 50%;
      vertical-align: middle;
      position: relative;
      margin-top: 25vw;
      .close-ad {
        position: absolute;
        cursor: pointer;
        font-size: 24px;
        top: -10px;
        color: #fff;
        z-index: 99;
        right: 0;
      }
    }
  }
}
</style>
