export default {
  // 购物车 - 红点
  getBadge: {
    method: "post",
    // url: "/shoppingcart/badge/read",
    url: "/cart/badge",
  },
  // 购物车 - 列表
  getCartList: {
    method: "post",
    // url: "/shoppingcart/goods/index",
    url: "/cart/index",
  },
  // 购物车 - 修改数量
  updateNum: {
    method: "post",
    // url: "/shoppingcart/goods/update",
    url: "cart/addGoods",
  },
  // 购物车 - 删除
  delCart: {
    method: "post",
    // url: "/shoppingcart/batchdelete/save",
    url: "cart/delGoods",
  },
  // 购物车 - 选中状态
  cartChoose: {
    method: "post",
    url: "/cart/chose",
  },
  // 购物车 - 提交
  submitCart: {
    method: "post",
    url: "/order/preSave",
  },
  // 预下单-读取
  getPreorder: {
    method: "post",
    url: "/order/preRead",
  },
  // 销毁订单
  orderDestroy: {
    method: "post",
    url: "/order/destroy",
  },
  // 预下单-赠品列表
  giftGoods: {
    method: "post",
    url: "/order/giftGoods",
  },
  // 支付方式 app、pc、h5
  getDeliverymethod: {
    method: "post",
    url: "/order/delivery",
  },
  // 预下单-运费读取
  getFreight: {
    method: "post",
    url: "/order/freight/read",
  },
  // 预下单-收货地址
  getAddress: {
    method: "post",
    url: "/order/address",
  },
  // 预下单-优惠券
  getCoupon: {
    method: "post",
    url: "/order/coupon",
  },
  //预下单-发票类型
  getInvoice: {
    method: "post",
    url: "/order/invoice/index",
  },
  //预下单-是否随货资质
  getQualification: {
    method: "post",
    url: "/order/qualification/index",
  },
  //预下单-支付方式
  getPay: {
    method: "post",
    url: "/order/payinfo",
  },
  //预下单-确认订单
  submitOrder: {
    method: "post",
    url: "/order/save",
  },
  // 查询支付结果
  payStatus: {
    method: "post",
    url: "/order/paystatus",
    isLoading: "false"
  },
  //购物车属性
  getAttr: {
    method: "post",
    url: "/cart/notice",
  },
  //微信支付
  weixinpay: {
    method: "post",
    url: "/order/weixin/pay",
  },
};
