export default {
  // 商品列表
  getGoods: {
    method: "post",
    url: "/goods/index",
  },
  // 商品列表-搜索条件
  getGoodsFilter: {
    method: "post",
    url: "/goods/filter/read",
  },
  // 商品列表-商品筛选分类
  getGoodsFilterCate: {
    method: "post",
    url: "/goods/filter",
  },
  // 商品列表-商品搜索 - 厂家
  getGoodsFilterscqy: {
    method: "post",
    url: "/condition/factory",
  },
  // 商品列表-商品搜索 - 规格
  getGoodsFiltersku: {
    method: "post",
    url: "/goods/filter/sku",
  },
  // 商品详情
  getGoodsDel: {
    method: "post",
    url: "/goods/read",
  },
  // 商品-加入购物车
  addCart: {
    method: "post",
    url: "/cart/addGoods",
  },
  // 商品-添加收藏
  addCollect: {
    method: "post",
    url: "/user/collectSave",
  },
  // 商品-删除收藏
  delCollect: {
    method: "post",
    url: "/user/collectDel",
  },
  // 商品详情 - 同类热销
  getHotGoods: {
    method: "post",
    url: "/goods/hotsales",
  },
  // 为您推荐
  getRecommend: {
    method: "post",
    url: "/goods/recommend",
  },
  // 申请开通商品
  goodsApply: {
    method: "post",
    url: "/goods/apply",
  },
  // 查看商品资质
  goodslicense: {
    method: "post",
    url: "/goods/license",
  },
  // 活动中心一级分类
  activeTop: {
    method: "post",
    url: "/activity/centerTop",
  },
  // 活动中心二级分类
  activeCate: {
    method: "post",
    url: "/activity/centerCate",
  },
  // 秒杀专区
  seckill: {
    method: "post",
    url: "/activity/seckill",
  },
};
