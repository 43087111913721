export default {
  // 新闻页面列表
  getNews: {
    method: "post",
    url: "/tools/newsType",
  },
  // 新闻分类列表下的新闻
  getNewsList: {
    method: "post",
    url: "/tools/news",
  },
  // 新闻页面详情
  getNewsDel: {
    method: "post",
    url: "/web/new/view",
  },
  // 新闻中心分类
  getNewsCate: {
    method: "post",
    url: "/tools/cates",
  },
  // 新闻中心分类列表
  getcateslist: {
    method: "post",
    url: "/tools/cateslist",
  },
  // 新闻中心详情
  getNewsCateInfo: {
    method: "post",
    url: "/tools/catesinfo",
  },
};
