import router from "@/router";
import { MessageBox, Message } from "element-ui";
import api from "@/api";
import { LgetItem, LreItem } from "../utils/storage";
// import store from "@/store";

/**
 * 解析 query 值
 */
export function getQueryKw(kw) {
  let list = {};

  if (kw) {
    let queryVal = kw;
    queryVal = queryVal.replace("?", "");
    let queryValArr = queryVal.split("&");

    queryValArr.forEach((item) => {
      let arr = item.split("=");
      list[arr[0]] = arr[1];
    });
  }

  return list;
}

/* 
一维数组转二维数组 
arr：原数组
subArrayLength：每一项多少
 */
export function to2DArray(arr, subArrayLength) {
  let result = [];
  // console.log("原数组---",arr)
  // console.log("每页的数量---",subArrayLength)
  if (arr.length <= subArrayLength) {
    // console.log("111----",arr)
    const newArr = [arr];
    // console.log("新数组----",newArr)
    return newArr;
  } else {
    for (var i = 0; i < arr.length; i += subArrayLength) {
      result.push(arr.slice(i, i + subArrayLength));
    }
    return result;
  }
}

/**
 *
 * @param {*} r ：图片
 * 弹出层图片超出可视区域75%
 * 按比例裁剪重新赋值宽高
 */
export function loadImage(r) {
  let img = r.target;
  if (img.src != "") {
    let imgsize = imgTrim(img.src);
    img.style.width = `${imgsize.w}px`;
    img.style.height = `${imgsize.h}px`;
  }
}

export function imgTrim(img) {
  let max_w = Math.floor(window.innerWidth * 0.5);
  let max_h = Math.floor(window.innerHeight * 0.5);

  let imgval = new Image();
  imgval.src = img;

  let img_w = imgval.width;
  let img_h = imgval.height;

  let dis_w = Number(imgval.width / max_w).toFixed(2);
  let dis_h = Number(imgval.height / max_h).toFixed(2);

  let cj_w = img_w;
  let cj_h = img_h;

  if (dis_w > 1 || dis_h > 1) {
    if (dis_w > dis_h) {
      cj_w = Math.floor(img_w / dis_w);
      cj_h = Math.floor(img_h / dis_w);
    } else {
      cj_w = Math.floor(img_w / dis_h);
      cj_h = Math.floor(img_h / dis_h);
    }
  }

  let data = {};
  data.w = cj_w;
  data.h = cj_h;
  return data;
}

//封装跳转
export function handleJump(jump) {
  // console.log("jump---------", jump);
  // let menuList = store.state.menuData;
  // let ind = menuList.findIndex((item) => item.value == jump.value);

  if (jump.value) {
    // let jumpArr = jump.value.split("?");
    // console.log("路径拆分----", jumpArr);
    if (jump.value == "/index/goodsDetail") {
      //商品详情页打开新标签
      let routeUrl = router.resolve({
        path: jump.value,
        query: { value: jump.options },
      });
      window.open(routeUrl.href, "_blank");
    } else {
      if (jump.options) {
        router.push({
          path: jump.value,
          query: { value: jump.options },
        });
        // store.commit("CHANGE_TAB", ind);
      } else {
        router.push({
          path: jump.value,
          // query: { value: jump.options },
        });
        // store.commit("CHANGE_TAB", ind);
      }
    }
  }
}
// 顶部跳转
//封装跳转
// export function topJump(jump) {
//   console.log("jump---------", jump);
//   let preStr = jump.pcJump.value.substr(0, 4);
//   console.log("截取-----", preStr);
//   if(preStr == "http")
// }

export function getZoneInfo(value) {
  return new Promise((resolve, reject) => {
    if (value) {
      let index = value.indexOf("menu_id");
      let result = value.substr(index + 8, value.length);
      api("zone.getZone", { menu_id: result })
        .then((res) => {
          resolve(res.data);
        })
        .catch(() => {
          reject(false);
        });
    }
  });
}

export function handleData(requestapi, params, message) {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(`是否${message}?`, "温馨提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        const res = await api(requestapi, params);
        if (!res) return reject(false);
        Message({
          type: "success",
          message: `${message}成功!`,
        });
        resolve(true);
      })
      .catch(() => {});
  });
}

export function isLogin() {
  if (LgetItem("token")) return true;
  else return false;
}

export function backToLogin() {
  return new Promise((resolve, reject) => {
    // console.log("token缓存----", LgetItem("token"));
    if (LgetItem("token")) resolve(true);
    else {
      Message({
        type: "error",
        message: "请先登录",
      });
      setTimeout(() => {
        router.replace("/login");
        LreItem("token");
        LreItem("info");
      }, 1000);
      reject();
    }
  });
}

// 金额格式化
export function formatAmount(num, decimal = 2, split = ",") {
  if (!num) {
    num = 0;
  }
  function thousandFormat(num) {
    const len = num.length;
    return len <= 3 ? num : thousandFormat(num.slice(0, len - 3)) + split + num.slice(len - 3, len);
  }
  if (isFinite(num)) {
    // num是数字
    if (num === 0) {
      // 为0
      return num.toFixed(decimal);
    } else {
      // 非0
      let res = "";
      let dotIndex = String(num).indexOf(".");
      if (dotIndex === -1) {
        // 整数
        if (decimal === 0) {
          res = thousandFormat(String(num));
        } else {
          res = thousandFormat(String(num)) + "." + "0".repeat(decimal);
        }
      } else {
        // 非整数
        // js四舍五入 Math.round()：正数时4舍5入，负数时5舍6入
        // Math.round(1.5) = 2
        // Math.round(-1.5) = -1
        // Math.round(-1.6) = -2
        // 保留decimals位小数
        const numStr = String((Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal)); // 四舍五入，然后固定保留2位小数
        const decimals = numStr.slice(dotIndex, dotIndex + decimal + 1); // 截取小数位
        res = thousandFormat(numStr.slice(0, dotIndex)) + decimals;
      }
      return res;
    }
  } else {
    return "--";
  }
}

/**导出xlsx文件
 *  header 表头
 *  filterVal 表格字段
 *  list  表格数据
 *  filename  下载文件名
 */
import { ElMessage } from "element-ui";
export function saveFile(header, filterVal, list, filename = "", autoWidth = false, bookType = "xlsx") {
  return new Promise((resolve, reject) => {
    import("@/utils/excel")
      .then((excel) => {
        const data = formatJson(filterVal, list);
        excel.export_json_to_excel({
          header,
          data,
          filename,
          autoWidth,
          bookType,
        });
        resolve(true);
      })
      .catch(() => {
        ElMessage.error("下载失败");
        reject();
      });
  });
}
function formatJson(filterVal, jsonData) {
  return jsonData.map((v) =>
    filterVal.map((j) => {
      return v[j];
    })
  );
}
