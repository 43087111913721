import Vue from "vue";
import Vuex from "vuex";
import api from "@/api";
import { LgetItem, LsetItem } from "@/utils/storage";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    // 顶部搜索条件
    searchKey: {
      kw: "", //商品搜索框值
    },
    scqyUseKW: {
      kw: "", //商品搜索框值
    },
    closeKWlist: false,
    goodsListCate: false,
    num: 0, //购物车红点
    userInfo: LgetItem("info"),
    list: {},
    total: 0,
    tabIndex: -1,
    pageName: [],
    menuData: [],
    settingData: {},
    homeComData: {},
    homeData: {},
    searchData: {},
  },
  getters: {
    searchKey: (state) => state.searchKey,
    scqyUseKW: (state) => state.scqyUseKW,
    closeKWlist: (state) => state.closeKWlist,
    goodsListCate: (state) => state.goodsListCate,
    num: (state) => state.num,
    list: (state) => state.list,
    userInfo: (state) => state.userInfo,
    total: (state) => state.total,
    tabIndex: (state) => state.tabIndex,
    pageName: (state) => state.pageName,
    menuData: (state) => state.menuData,
    settingData: (state) => state.settingData,
    homeComData: (state) => state.homeComData,
    homeData: (state) => state.homeData,
    searchData: (state) => state.searchData,
  },
  mutations: {
    setSearchKey(state, data) {
      state.searchKey = data;
    },
    setScqyUseKey(state, data) {
      state.scqyUseKW = data;
    },
    setCloseKWlist(state, data) {
      state.closeKWlist = data;
    },
    setGoodsListCate(state, data) {
      state.goodsListCate = data;
    },
    SAVE_BADGE(state, data) {
      state.num = data;
    },
    SAVE_CART(state, data) {
      state.list = data;
      state.total = data.total_price;
    },
    CHANGE_TAB(state, data) {
      state.tabIndex = data;
    },
    CHANGE_PAGENAME(state, data) {
      state.pageName = data;
    },
    CHANGE_MENUDATA(state, data) {
      state.menuData = data;
    },
    SAVE_SETTING(state, data) {
      state.settingData = data;
    },
    SAVE_HOMECOMDATA(state, data) {
      state.homeComData = data;
    },
    SAVE_HOMEDATA(state, data) {
      state.homeData = data;
    },
    SAVE_SEARCHDATA(state, data) {
      state.searchData = data;
    },
    SAVE_USERINFO(state, data) {
      state.userInfo = data;
    },
    DEL_USERINFO(state, data) {
      state.userInfo = data;
    },
  },
  actions: {
    getBadge({ commit }) {
      api("cart.getBadge").then((res) => {
        commit("SAVE_BADGE", res.data);
      });
      // api("cart.getCartList").then((res) => {
      //   commit("SAVE_CART", res.data);
      // });
    },
    getUserInfo({ commit }) {
      api("account.getInfo").then((res) => {
        if (res.code == 200) {
          commit("SAVE_USERINFO", res.data);
          LsetItem("info", res.data);
        }
      });
    },
    delUserInfo({ commit }) {
      commit("DEL_USERINFO", "");
      LsetItem("info", "");
    },
  },
});

export default store;
