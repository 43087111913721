let config = {
  host: "",
  port: "",
  baseUrl: "",
  type: process.env.NODE_ENV,
};

if (config.type === "development") {
  config.baseUrl = "https://newapi.scytyy.net/api/";
  // config.baseUrl = "https://dsapi.huaqingyiyao.com/";
  // config.baseUrl = "http://b2b.api.wiscode.cn/api";
  // config.baseUrl = "/test";
  config.port = "";
  config.host = "";
} else if (config.type === "production") {
  config.baseUrl = "https://newapi.scytyy.net/api/";
  // config.baseUrl = "https://dsapi.huaqingyiyao.com/";
  // config.baseUrl = "http://b2b.api.wiscode.cn/api";
  // config.baseUrl = "/test";
  config.port = "";
  config.host = "";
} else if (config.type === "test") {
  config.baseUrl = "https://newapi.scytyy.net/api/";
  // config.baseUrl = "https://dsapi.huaqingyiyao.com/";
  // config.baseUrl = "http://b2b.api.wiscode.cn/api";
  // config.baseUrl = "/test";
  config.port = "";
  config.host = "";
}
export default config;
