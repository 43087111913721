export default {
  // 用户登录
  getLogin: {
    method: "post",
    // url: "/session/login/save",
    url: "/user/login",
  },
  // 广告图获取
  getAD: {
    method: "post",
    url: "/get/adv/list",
  },
  // 经营类型
  getType: {
    method: "post",
    url: "/condition/unitType",
  },
  // 地区
  getCity: {
    method: "post",
    url: "/condition/cityList",
  },
  // 验证码
  getCode: {
    method: "post",
    url: "/tools/captcha",
  },
  // 忘记密码
  findPass: {
    method: "post",
    url: "/user/resetPassword",
  },
  // 注册
  register: {
    method: "post",
    url: "/user/regSave",
  },
  // 协议
  agreement: {
    method: "post",
    url: "/app/registration/agreement",
  },
};
